.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

mark {
  background-color: transparent;
  color: rgb(213, 200, 102);
  font-style: italic;
}

main {
  padding: 2vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: white;
  font-family: 'dm';
  letter-spacing: -0.06em;
}

main h1 {
  /*font-family: 'dm', monospace;*/
  font-style: italic;
}

main p {
  margin-bottom: 2vh;
}

.App-link {
  color: #61dafb;
}
