.social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 2vh;
}

.rounded {
    border-radius: 50%;
}

.avatar-icon {
    width: 10vh;
    height: 10vh;
    margin-bottom: 2vh;
    box-shadow: 0 0 1.5vh 1vh rgb(213, 200, 102);
}

.social-icon {
    margin-left: 0.5vh;
    margin-right: 0.5vh;
}